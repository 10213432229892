<template>
    <vs-popup class="memberCommentary_popup" title="Ajout commentaire" :active.sync="popupActive">
        
        <template v-if="uid_list.length==0">
            <p>Aucun membre choisie !</p>
        </template>
        <template v-else>
            <div class="w-3/3 px-5">
                <vs-textarea v-model="commentary" />
            </div>
            <vs-divider/>
            <vs-row>
                <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                    <div></div>
                    <vs-button color="success" type="filled" v-on:click="add()" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>
                </vs-col>
            </vs-row>
        </template>
    </vs-popup>
</template>

<style lang="scss">
.memberCommentary_popup
{

}
</style>

<script>

import Commentary from '@/database/models/commentary';

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            uid_list : [],
            commentary   : '',
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){

    },
    methods:{
        openPopup( uid_list, callback )
        {
            this.uid_list    = uid_list
            this.commentary      = ''
            this.callback    = null
            this.popupActive = true

            if(callback)
                this.callback = callback
        },
        add()
        {
            let compte = 0;

            let end = ()=>{
                compte++

                if(compte >= this.uid_list.length )
                if(this.callback)
                    this.callback()
            }

            //update member
            if(this.commentary!='')
            for( var i=0; i<this.uid_list.length; i++)
            {
                Commentary.add( { member:this.uid_list[i].uid, commentary:this.commentary, flag:0 })
                .then(end)
                .catch((err)=>{
                    alert(err)
                    end()
                })
            }

            //fermeture popup
            this.popupActive = false
        },
    }
}

</script>