<template>
    <vs-popup class="CP_popup" title="Rechercher une ville" :active.sync="popupActive">

        <vs-row vs-align="center">
            <vs-col vs-w="3" vs-sm="6" class="mb-6" >
                 <vs-button   type="border" @click="selectCountry('fr')" class="boutonP "    v-bind:class="{ 'active':  pays  == 'fr' }">France</vs-button>
            </vs-col>
            <vs-col vs-w="3" vs-sm="6" class="mb-6">
                <vs-button   type="border" @click="selectCountry('be')" class="boutonP" >Belgique</vs-button>
            </vs-col>
            <vs-col vs-w="3" vs-sm="6" class="mb-6">
               <vs-button   type="border" @click="selectCountry('lu')" class="boutonP" >Luxembourg</vs-button>
            </vs-col>
            <vs-col vs-w="3" vs-sm="6" class="mb-6">
                 <vs-button   type="border" @click="selectCountry('su')" class="boutonP" >Suisse</vs-button>
            </vs-col>

         
        </vs-row>
        <!-- <vs-select v-model="pays" class="w-full select-large" v-on:change="reset()">
            <vs-select-item value="fr" text="France" selected/>
            <vs-select-item value="be" text="Belgique" />
            <vs-select-item value="lu" text="Luxembourg" />
            <vs-select-item value="su" text="Suisse" />
        </vs-select> -->
        <br/>
        <vs-input class="w-full" v-model="cp"/>
        <br/>

        <template v-if="city_list.length==0">
            <h3 class="text-center">Saisissez un code postal !</h3>
        </template>

        <template v-else>
            <ul>
                <li v-for="(city, index) in city_list" :key="index" class="click" v-on:click="select(city)">{{ city }}</li>
            </ul>
        </template>
    </vs-popup>
</template>

<style lang="scss">
.CP_popup
{
 li{
     height:40px;
     font-size:1.5em;
     text-align:center;
 }
}

.boutonP{
    width: 90%;
   
}

.active{
    border-color: green;
    
}
</style>

<script>
// import func from 'vue-editor-bridge'

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:undefined,
            cp:'',
            pays:'fr',
            city_list:[],
        }
    },
    watch:{
        $route (){
            
        },
        cp(){
            //if(this.cp.length>3)
            //    return;

            this.$srvApi.Req('get','public/cp/'+this.pays+'/'+this.cp )
            .then((list)=>{
                
                this.city_list = list.data
            })
        }
    },
    beforeMount:function(){

    },
   
    methods:{
        getColor(p){
            
            if ( this.pays == p){
               
                return "background:black";
               
            }
            else{
               
                return "background:#ffffff";
            
            }
        },
        selectCountry(country){
            this.pays=country;
            this.city_list = [];
            this.cp = '';
        },

        openPopup( cp, callback )
        {
            this.popupActive = true
            this.cp = cp;

            if(callback)
                this.callback = callback;
        },
        select( city )
        {
            if(this.callback)
                this.callback( this.cp, city )

            this.popupActive = false
            //envoi signal de refresh
            this.$emit('refresh')
        },
        reset()
        {
            this.city_list = [];
            this.cp = '';
        }
    }
}

</script>