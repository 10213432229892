<template>
    <!-- Member info -->
    <div class="vx-col w-full sm:w-2/2 lg:w-3/3 mb-base comp_mbinfo">
        <vx-card v-bind:card-background="GetBtnCardColor()">
        <div class="grid-layout-container alignment-block">
            <vs-row class="ban_member">
                    <vs-col vs-w="2" vs-sm="12" vs-xs="12" style="width:120px;" class="img_posi_col">
                        <div class="con-img img_posi">
                            <img  key="onlineImg" :src="photoURL" alt="user-img" width="110" height="110" class="rounded-full shadow-md cursor-pointer block" v-on:click="toMemberFiche()" />
                        </div>
                    </vs-col>

                    <vs-col vs-w="3" vs-sm="4" vs-xs="12" >
                        <div class="info_mbr_ban">
                            <h3 class="user_name nowrap">{{ StrCapital(member.first_name+' '+member.last_name) }}</h3>
                            <span class="user_info nowrap">{{ GetLocalPhone(member.phone) }}</span>
                        </div>
                    </vs-col>

                    <vs-col vs-w="3" vs-sm="4" vs-xs="12" style="text-align:center;">
                        <!--<h3 class="user_name"></h3>--><div class="spacer_block" style="height:25px;"></div>
                        <span class="user_info nowrap">{{ GetAuthName(member.type) }} / {{ GetEtatName(member.active) }}</span>
                    </vs-col>

                    <vs-col vs-w="3" vs-sm="4" vs-xs="12" style="text-align:right;">
                        <h3 class="user_name nowrap">Créances : {{ creance.toFixed(2) }} €</h3>
                        <span class="user_info nowrap">CA : {{ consumption.toFixed(2) }} €</span>
                    </vs-col>

                    <vs-col vs-w="1" vs-sm="12" vs-xs="12"  class="member_action">
                        <template v-if="member.type!='a' || admin">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <vs-button radius icon="icon-plus" icon-pack="feather" size="large" class="button_add cursor" />

                            <vs-dropdown-menu style="width:200px;">
                                    <vs-dropdown-item v-on:click="CreateCaisse('fa')">Créer facture</vs-dropdown-item>
                                    <vs-dropdown-item v-on:click="CreateCaisse('re')">Créer reçu</vs-dropdown-item>
                                    <vs-dropdown-item v-on:click="CreateCaisse('av')">Créer avoir</vs-dropdown-item>
                                    <vs-dropdown-item v-on:click="ToSepa()">Prélèvement SEPA</vs-dropdown-item>

                                    <vs-dropdown-item v-on:click="StatusMember()" divider>Modifier etat</vs-dropdown-item>
                                    <vs-dropdown-item v-on:click="TypeMember()">Modifier type</vs-dropdown-item>
                                    <template v-if="admin">
                                        <vs-dropdown-item v-on:click="RGPDMember()">Archiver RGPD</vs-dropdown-item>
                                    </template>

                                    <vs-dropdown-item v-on:click="AddComMember()" divider>Commentaire</vs-dropdown-item>
                                    <!--<vs-dropdown-item v-on:click="AddResa()" >Ajouter réservation</vs-dropdown-item>-->
                                    <vs-dropdown-item v-on:click="SendSMS()">Envoyer SMS</vs-dropdown-item>
                                    <vs-dropdown-item v-on:click="SendEmail()">Envoyer Email</vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                        </template>
                    </vs-col>
                </vs-row>
            </div>
        </vx-card>

        <StatusMember ref="StatusMember" />
		<TypeMember ref="TypeMember" />
        <RGPDMember ref="RGPDMember" />
        <CommentaryMember ref="CommentaryMember" />
    </div>
</template>

<style lang="scss">
.comp_mbinfo
{
    .img_posi
    {
        position:absolute;
        left:20px;
        top:-10px;
    }
    .member_action
    {
        text-align:right;
    }

    @media screen and (max-width: 900px) {
        .member_action{
            padding-top:20px;
            text-align:center !important;
        }
        .img_posi_col
        {
            height:100px !important;
            .img_posi{
                left:50%;
                margin-left:-50px;
            }
        }
        .ban_member .vs-col{
            text-align:center !important;
            padding-top:20px;
        }
        .activity_title{
            display:none;
        }
    }
    @media screen and (max-width: 600px) {
        .spacer_block{
            display:none;
        }
    }

    .name_posi{
        text-align:right;
    }
    .user_name{
        color:white;
    }
    .user_info{
        color:#D0D0D0;
    }
    .button_add{
        color:black;
        background-color: white !important;
        i{
            font-weight: bold !important;
        }
    }
}
</style>

<script>

import Utils from '@/assets/utils/utils';
import Phone from '@/assets/utils/phone';
import Credit from '@/assets/utils/credit';

import Member  from '@/database/models/member';
import Booking from '@/database/models/booking';

import StatusMember from '@/components/member/status.vue'
import TypeMember from '@/components/member/type.vue'
import RGPDMember from '@/components/member/rgpd.vue'
import CommentaryMember from '@/components/member/commentary.vue'

export default {
    components:{
        StatusMember,
        TypeMember,
        RGPDMember,
        CommentaryMember,
	},
    props: {
        uid:{
            type:String,
            default: '?',

            admin:false,
        }
    },
    data(){
        return{
            photoURL: require("@/assets/images/portrait/small/avatar.png"),
            member:{
                first_name:'',
                last_name:'',
                phone:'',
            },
            creance:0,
            consumption:0,

            admin:false,
        }
    },
    watch:{
        uid(){
            this.load()
        }
    },
    mounted:function()
	{
        this.load()
        this.RoleAdmin()
    },
    methods:{
        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        },
		StrCapital:function(str){
            return Utils.StrCapital(str);
        },
        getResaColor:function(type)
        {
            return { 'color':Booking.getColor(type) };
        },
        GetLocalPhone:function(phone)
        {
            return Phone.getLocalPhone(phone);
        },
        GetAuthName:function(val)
        {
            return Member.getAuthName({type:val});
        },
        GetEtatName:function(val)
        {
            return Member.getEtatName({active:val});
        },
        GetBtnCardColor:function()
		{
            let type = Member.getTypeSpec( this.member );
            return 'linear-gradient(120deg, '+Member.color[type]+','+Member.color[type]+','+Member.color['f'+type]+')';
        },


        //-----------------------------------------
		//Action modif
		//-----------------------------------------
		StatusMember(){
			this.$refs.StatusMember.openPopup([{uid:this.uid}], ()=>
			{
				this.$emit('refresh')
			})
		},
		TypeMember(){
			this.$refs.TypeMember.openPopup([{uid:this.uid}], ()=>
			{
				this.$emit('refresh')
			})
        },
        RGPDMember(){
			this.$refs.RGPDMember.openPopup([{uid:this.uid}], ()=>
			{
                alert('Archivage RGPD effectué !')
				this.$router.push('/admin/member')
			})
        },
        AddComMember(){
            this.$refs.CommentaryMember.openPopup([{uid:this.uid}], ()=>
			{
                this.$emit('refresh')
			})
        },
        AddResa()
        {
            //TODO
        },
        CreateCaisse:function(type)
        {
            this.$router.push("/admin/checkout/"+this.uid+"/"+type).catch(() => {})
        },
        toMemberFiche:function()
        {
            this.$router.push("/admin/member/"+this.uid).catch(() => {})
        },
        ToSepa:function()
        {
            this.$router.push("/admin/sepa/"+this.uid).catch(() => {})
        },


        
        load: async function( input_uid )
        {
            if(input_uid)
                this.uid = input_uid
            
            //-----------------------------------------------------------
            //get info member
            Member.getMemory( this.uid, (member)=>
            {
                this.member = member
            })

            //-----------------------------------------------------------
            //Build créance
            this.creance = await Credit.getMemberCreance( this.uid );

            //-----------------------------------------------------------
            //Build CA
             this.consumption = await Credit.getMemberConsumption( this.uid );
        },



        SendSMS(){
			window.api_selected = [this.member];
			this.$router.push('/admin/sms/groupe');
		},
        SendEmail(){
			window.api_selected = [this.member];
			this.$router.push('/admin/email/groupe');
		},
    }
}

</script>